:root {
  --logo-padding: 2rem;
  --section-padding: 1rem;
  --cover-band-logo-width: 5rem;
  --main: rgb(0, 182, 182);
  --main-mid: rgb(18 144 170);
  --main-dark: rgb(0, 41, 41);
}

@media screen and (max-width: 900px) {
  :root {
    --logo-padding: 1rem;
    --section-padding: 2rem 1rem;
    --cover-band-logo-width: 1.7rem;
  }
}

@keyframes rotation {
  to {
    transform: rotate(360deg) translateX(-50%) translateY(-50%);
  }
}

html,
body {
  padding: 0;
  margin: 0;
}

html {
  /* Amatic font is small so we compensate */
  font-size: 200%;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

#root {
  font-family: 'Amatic SC', sans-serif;
}

a {
  color: var(--main);
  font-weight: 700;
}

h1 {
  font-family: 'Bahiana';
}

h2 {
  font-size: 1rem;
  margin: 1rem 0 0;
}

h2 + p {
  margin-top: 0;
}

ul {
  text-align: left;
  margin-left: 1rem;
}

.app {
  text-align: center;
  padding: 0;
  margin: 0;
}

section {
  padding: var(--section-padding);
}

iframe {
  max-width: 100%;
}