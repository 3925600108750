.date {
    background: var(--main-dark);
    color: white;
    display: grid;
    grid-template:
        "a b" 40px
        "c b" 40px;
    grid-template-rows: auto;
    align-content: center;
    justify-content: start;
    align-items: center;
    gap: 0em 0.4rem;
    padding: 0.2rem;
    padding-left: 3rem;
    border-radius: 5px 5px 0 0;

    &__day-of-week {
        font-size: 0.7rem;
        font-weight: 600;
        grid-area: a;
    }

    &__day {
        font-size: 1.1rem;
        font-weight: 600;
        grid-area: c;
    }

    &__year {
        font-size: 0.6rem;
    }

    &__time {
        font-size: 0.9rem;
        grid-area: b;
    }
}

@media screen and (max-width: 900px) {
    .date {
        &__day {
            font-size: 0.9rem;
        }

        &__time {
            font-size: 0.7rem;
        }
    }
}