.gig {
    padding: 0;
    font-weight: 500;
    display: flex;
    align-items: center;
    margin: 0.5rem 0 1.5rem 2.2rem;
    border-radius: 8px;
    border: 4px solid white;
    position: relative;
}

.flyer {
    width: 100%;
}

.flyer-container {
    height: 5rem;
    width: 5rem;
    overflow: hidden;
    border-radius: 50%;
    border: 4px solid white;
    top: 50%;
    margin-top: -2.5rem;
    left: -2.5rem;
    position: absolute;
    background: var(--main-dark);
}

.info {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.details {
    display: grid;
    grid-template: 'a' 'b' 'c';
    padding: 0 0 0 3rem;
    align-items: center;
    justify-content: start;
}

.venue {
    font-weight: 600;
    grid-area: a;
}

.location {
    grid-area: b;
    font-size: 0.7rem;
}

.pin-icon {
    font-size: 0.7rem;
    vertical-align: middle;
    margin-right: 0.2rem;
}

.location-link {
    color: inherit;
    text-decoration: none;
    font-weight: 400;
    line-height: 1;
    display: block;
}

.more-info {
    line-height: 1;
    grid-area: c;
}

.more-info__link {
    color: var(--main-dark);
    font-size: 0.7rem;
    margin-right: 0.5rem;
    line-height: 1;
}

@media screen and (max-width: 900px) {
    .venue {
        font-size: .8rem;
    }
}