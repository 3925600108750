.navigation {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 1rem 0.2rem;
    z-index: 100;
    font-family: 'Bahiana';
}

.navigation.scrolled {
    background: linear-gradient(rgba(255, 255, 255, 0.884) 20%, rgba(255, 255, 255, 0) 80%);
}

.links {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.navigation a {
    flex: 1;
    text-align: center;
    text-decoration: none;
    padding: 0 0.5rem;
    cursor: pointer;
    font-weight: 700;
    display: block;
    color: rgb(17 81 81);
    text-shadow: 1px -1px 0px white;
}

@media screen and (max-width: 900px) {
    .navigation a {
        font-size: 0.8rem;
        padding: 0 0.2rem;
    }
}