#video {
    padding: 4rem 1rem;
}

.video-container {
    text-align: center;
    margin: 1rem;
    padding: .5rem;
    background: white;
    box-shadow: 0px 1px 4px 2px var(--main-mid);
    border-radius: 5px;
}

@media screen and (max-width: 900px) {
    #video {
        padding: 1rem 0;
    }
}