.image-gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    max-width: 35rem;
    margin: 0 auto;
    justify-content: center;
}

.photo-wrapper {
    height: 350px;
    width: 300px;
    cursor: pointer;
    position: relative;
    padding: 0.4rem;
    background: white;
    box-shadow: 0px 1px 4px 2px var(--main-mid);
    border-radius: 3px;
}

@media screen and (max-width: 900px) {
    #photos {
        padding: 2rem 0.3rem;
    }

    .photo-wrapper  {
        height: 230px;
        width: 170px;
        padding: 0.3rem;
    }
}

.photo {
    object-fit: cover;
    width: 100%;
    height: 100%;
    vertical-align: middle;
}