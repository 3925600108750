.intro {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
}

.intro-content {
  display: flex;
  position: relative;
}

.text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0.5rem;
  left: 50%;
  margin-left: -10rem;
  width: 20rem;
}

.special {
  padding-left: 10%;
  font-family: 'Bahiana';
  position: relative;
  top: -1rem;
  z-index: 2;
  font-size: 1.5rem;
  letter-spacing: 6px;
  text-transform: uppercase;
  color: rgb(18 144 170);
  transform: rotate(-10deg);
  text-shadow: 2px 2px 0px hsl(0deg 0% 100%), -2px -2px 0px rgb(255 255 255 / 99%), 3px -2px rgb(255 255 255), -2px 2px rgb(255 255 255);
}

.logo {
  pointer-events: none;
  padding: 1rem;
  position: relative;
}

.members-image {
  padding: 1rem;
  width: 100%;
  height: auto;
}

@media screen and (orientation: landscape) and (min-width: 1000px) {
  .members {
    padding-top: 2rem;
  }
}

/* Landscape phones */
@media screen
  and (orientation: landscape)
  and (max-height: 400px) {
    .intro {
      justify-content: center;
    }
  
    .intro-content {
      display: block;
    }

    .logo {
      padding: 1rem 2rem;
      width: 100%;
      height: auto;
    }

    .special {
      font-size: 1rem;
      letter-spacing: 3px;
    }

    .members-image {
      /* prevent content collision on phone-landscape */
      height: 200px;
      width: auto;
    }
}

@media screen and (orientation: portrait) {
  .intro-content {
    display: block;
  }

  .logo {
    width: 100%;
    height: auto;
    padding: 1rem 3rem;
  }

  .text {
    width: 12rem;
    margin-left: -6rem;
  }
}

@media screen
  and (orientation: portrait)
  and (max-width: 500px) {
  .intro {
    justify-content: center;
  }

  .intro-content {
    display: block;
  }

  .logo {
    padding: 1rem 1.6rem 0.7rem;
  }
  
  .special {
    top: -1rem;
    font-size: .8rem;
    letter-spacing: 2px;
    margin: 0 2rem;
  }
}