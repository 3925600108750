#songs {
    background-image: url('./images/panel-cyan.webp');
    background-size: cover;
    background-position: center;
    color: white;
}

.bignumber {
    font-size: 1.7rem;
    font-weight: 700;
}

.cover-bands {
    display: flex;
    flex-wrap: wrap;
}

.cover-band {
    flex: 1 1 var(--cover-band-logo-width);
    display: flex;
    align-items: center;
    min-height: 6rem;
    max-width: 100%;
    margin: 0 1rem;
}

@media screen and (max-width: 900px) {
    .cover-band {
        min-height: 4rem;
    }
}

.cover-band-logo {
    width: 100%;
    height: auto;
}