.gigs {
    background-image: url('./images/panel-cyan.webp');
    background-size: cover;
    background-position: center;
}

.gigs-content {
    text-align: left;
    max-width: 15rem;
    margin: 0 auto;
}

.gigs ul {
    list-style: none;
    padding: 0;
    margin: 0;
}